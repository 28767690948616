import getEnv from '../getEnv';

const environment = getEnv();

const envConfig = (env = 'sand') => ({
	backofficeApiUrl: {
		prod: 'https://api.brighthr.com/v1/backoffice',
		sand: 'https://sandbox-api.brighthr.com/v1/backoffice'
	}[env],
	backofficeApiScopes: {
		prod: ['api://d6194c1e-a51f-43e5-be60-63065f85d162/api.full-access'],
		sand: ['api://804cd205-da7b-41a3-9ef1-71680bb7d635/api.full-access']
	}[env]
});

export default () => ({
	msGraphApiUrl: 'https://graph.microsoft.com/v1.0/me',
	msGraphApiScopes: ['User.Read'], // MSAL adds OIDC scopes by deafult (openid, profile, email), no need to request here
	environment,
	...envConfig(environment)
});
