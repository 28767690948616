import React from 'react';
import ReactDOM from 'react-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { getMsalConfig } from './utils/config/msalConfig';
import getEnv from './utils/getEnv';
import App from './App';
import '@brighthr/component-datepicker/dist/index.css';
import './index.css';

const environment = getEnv();
const msalConfig = getMsalConfig(environment);

// MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
	<React.StrictMode>
		<MsalProvider instance={msalInstance}>
			<App />
		</MsalProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
