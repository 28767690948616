import { React, useState } from 'react';
import getConfig from '../../utils/config';
import Section from '../../components/Layout/Section';
import FormButton from '../../components/FormButton';

const { backofficeApiUrl } = getConfig();

const getPasswordScramble = (accessToken, email) =>
	fetch(`${backofficeApiUrl}/password/scramble/${email}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		method: 'GET'
	});

const postPasswordScramble = (accessToken, accountId) =>
	fetch(`${backofficeApiUrl}/password/scramble/${accountId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		method: 'POST'
	});

export default ({ aadToken }) => {
	const [error, setError] = useState(false);
	const [getModel, setGetModel] = useState(null);
	const [scrambleComplete, setScrambleComplete] = useState(null);
	const [username, setUsername] = useState('');

	const clear = () => {
		setError(false);
		setGetModel(null);
		setScrambleComplete(false);
		setUsername('');
	};

	return (
		<div>
			<Section>
				<h1 className="text-2xl font-semibold">Password Scramble</h1>
				<p className="mb-1">
					Use this if you&apos;ve been informed of a users password being found on the
					dark web. It&apos;ll scramble the password to a random value.
					<br />
					Note the user will not be informed of this change by actions performed here.
				</p>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						getPasswordScramble(aadToken, username).then((res) => {
							if (res.ok) {
								res.json().then((data) => setGetModel(data));
							} else {
								setError(`Failed to get information for '${username}'`);
							}
						});
					}}
				>
					<label
						htmlFor="default-search"
						className="mb-2 text-sm font-medium text-gray-900 sr-only"
					>
						Search
					</label>
					<div className="relative">
						<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
							<svg
								aria-hidden="true"
								className="w-5 h-5 text-gray-500"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
								/>
							</svg>
						</div>
						<input
							type="email"
							value={username}
							onChange={(e) => {
								clear();
								setUsername(e.target.value);
							}}
							id="default-search"
							className="w-full p-4 pl-10 text-gray-900 border border-gray-600 rounded bg-gradient-to-r from-gray-100 via-gray-50 to-gray-200"
							placeholder="Search registered users..."
							required=""
						/>
						<div className="flex absolute right-1 bottom-2.5 space-x-2 mr-1">
							<FormButton buttonLabel="Search" type="submit" />
							<FormButton
								type="button"
								buttonLabel="Clear"
								buttonOnClick={() => {
									clear();
								}}
							/>
							<div className="w-[1px]" />
						</div>
					</div>
				</form>
				{getModel && (
					<>
						<hr />
						<div className="overflow-hidden bg-white shadow sm:rounded">
							<div className="px-4 py-5 sm:px-6">
								<h3 className="text-lg font-medium leading-6 text-gray-900">
									User Information
								</h3>
								<p className="max-w-2xl mt-1 text-sm text-gray-500">
									Account details.
								</p>
							</div>
							<div className="border-t border-gray-200">
								<dl>
									<div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt className="text-sm font-medium text-gray-500">
											Email Address
										</dt>
										<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
											{username}
										</dd>
									</div>
									<div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt className="text-sm font-medium text-gray-500">
											Password Last Updated
										</dt>
										<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
											{getModel.passwordLastChanged !== null
												? new Date(
														`${getModel.passwordLastChanged}Z`
												  ).toString()
												: 'N/A'}
										</dd>
									</div>
									<div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt className="text-sm font-medium text-gray-500" />
										<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
											<FormButton
												type="button"
												buttonLabel="Scramble Password"
												buttonOnClick={() => {
													postPasswordScramble(
														aadToken,
														getModel.accountId
													).then((res) => {
														if (res.ok) {
															setScrambleComplete(true);
															getPasswordScramble(
																aadToken,
																username
															).then((r) => {
																if (r.ok) {
																	r.json().then((data) => {
																		setGetModel(data);
																	});
																} else {
																	setError(
																		`Failed to refresh information for '${username}'`
																	);
																}
															});
														} else {
															setError(
																`Failed scramble password for '${username}'`
															);
														}
													});
												}}
											/>
										</dd>
									</div>
								</dl>
							</div>
						</div>
					</>
				)}
			</Section>
			{scrambleComplete && (
				<Section>
					<h2 className="text-xl font-semibold">All done</h2>
					<p>Email Jacob/Jenny and let them know.</p>
				</Section>
			)}

			{error && (
				<Section>
					<h2 className="pt-2 text-xl font-semibold text-red-500">
						Something went wrong!
					</h2>
					<p className="text-red-500">{error}</p>
				</Section>
			)}
		</div>
	);
};
