export default () => [
	{
		name: 'Home',
		href: '/home',
		icon: 'home'
	},
	{
		name: 'Password Scramble',
		href: '/password-scramble',
		icon: 'padlock-locked'
	}
];
