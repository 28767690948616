import React from 'react';
import Button from '@brighthr/component-button';

export default ({ buttonLabel, buttonOnClick, disabled, type }) => (
	<Button
		type={type}
		onClick={buttonOnClick}
		text={buttonLabel}
		color="accent"
		disabled={disabled}
	/>
);
