import { Link } from 'react-router-dom';
import Icon from '@brighthr/component-icon';
import cn from 'classnames';
import Button from '@brighthr/component-button';
import { useMsal } from '@azure/msal-react';
import { React, useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../../images/logo.svg';
import navigation from '../../../navigation';
import getConfig from '../../../utils/config';

const fetchData = (accessToken, apiHost) => {
	const options = {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		method: 'GET'
	};
	return fetch(`${apiHost}/hello`, options)
		.then((response) => response.json())
		.catch((error) => console.error(error));
};
const { backofficeApiUrl } = getConfig();

export default ({
	aadToken,
	userData,
	setUserData,
	menuOpen,
	setMenuOpen,
	currentPath,
	setCurrentPath,
	selectedClient,
	setIsLinkedFromDetails
}) => {
	const { instance } = useMsal();
	const [bffData, setBffData] = useState(null);

	useEffect(() => {
		if (!bffData && aadToken) {
			fetchData(aadToken, backofficeApiUrl).then((response2) => setBffData(response2));
		}
	}, [bffData, aadToken]);

	if (menuOpen && userData) {
		return (
			<div className="hidden fixed min-h-screen h-full md:flex max-w-[14.5rem] min-w-[14.5rem] flex-col flex-1 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700">
				<div className="absolute flex flex-col justify-center h-full left-48">
					<button
						type="button"
						className="w-10 rounded-l bg-gray-800 border-2 border-white h-[4rem]"
						label="button-label"
						onClick={() => {
							setMenuOpen(!menuOpen);
						}}
					>
						<Icon iconName="chevron-thin-left" className="fill-white" />
					</button>
				</div>

				<div className="flex items-center h-16 px-4 text-lg text-white bg-black">
					<Logo className="h-8 mr-4 text-brand-400" />
					Back Office
				</div>
				<div className="flex flex-col flex-1 overflow-y-auto border-gray-900 border-t-1">
					<nav className="pb-4">
						{navigation().map((item) => {
							const current =
								currentPath === item.href ||
								(item.href === '/home' && currentPath === '/client-details');
							if (
								bffData &&
								!(
									!bffData.roles.includes('super-administrator') &&
									item.name === 'Password Scramble'
								)
							) {
								return (
									<div
										className="flex items-stretch h-14"
										key={`${item.name}-row`}
									>
										<Link
											key={item.name}
											to={
												!(
													!(selectedClient === null) &&
													item.href === '/client-details'
												) && item.href
											}
											className={cn(
												current
													? 'bg-gradient-to-r from-black via-black/30 to-black/0 text-white'
													: 'text-gray-400 hover:bg-gray-700/70 hover:text-white',
												'group w-full flex items-center px-2 rounded font-medium'
											)}
											onClick={() => {
												if (item.href === '/home') {
													setIsLinkedFromDetails(false);
												}
												setCurrentPath(item.href);
											}}
										>
											<div className="flex items-center justify-center pr-3">
												<Icon
													className={cn(
														current
															? 'fill-white'
															: 'fill-gray-400 group-hover:fill-white',
														'h-7'
													)}
													aria-hidden="true"
													iconName={item.icon}
												/>
											</div>

											{item.name}
										</Link>
									</div>
								);
							}
							return null;
						})}
					</nav>
				</div>

				{userData && (
					<div className="w-full p-4 border-t border-gray-900 group">
						{userData && (
							<>
								<p className="text-sm font-medium text-white">
									{userData.displayName}
								</p>
								<p className="text-xs font-medium text-brand-200">
									{userData.userPrincipalName}
								</p>
							</>
						)}
						<div className="flex justify-center w-full pt-3">
							<Button
								type="submit"
								onClick={() => {
									setUserData(null);
									return instance.logoutRedirect({
										onRedirectNavigate: () =>
											// Return false if you would like to stop navigation to AAD logout after local logout
											false
									});
								}}
								text="Log out"
								color="primary"
							/>
						</div>
					</div>
				)}
			</div>
		);
	}
	if (userData) {
		return (
			<div className="absolute flex flex-col justify-center h-full">
				<button
					type="button"
					className="flex-grow-0 md:w-10 rounded-r bg-gray-800 border-b-2 border-t-2 border-r-2 border-white h-[4rem]"
					label="button-label"
					onClick={() => {
						setMenuOpen(!menuOpen);
					}}
				>
					<Icon iconName="chevron-thin-right" className="fill-white" />
				</button>
			</div>
		);
	}
	return null;
};
