import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useMsal,
	useAccount
} from '@azure/msal-react';
import { parseISO, format, toDate } from 'date-fns';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import PasswordScramble from './pages/PasswordScramble';
import ClientSearch from './pages/ClientSearch';
import ClientDetails from './pages/ClientDetails';
import ColumnLayout from './components/Layout/ColumnLayout';
import getConfig from './utils/config';

const { backofficeApiUrl, backofficeApiScopes } = getConfig();

const fetchData = (accessToken, apiHost) => {
	const options = {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		method: 'GET'
	};
	return fetch(`${apiHost}/hello`, options)
		.then((response) => response.json())
		.catch((error) => console.error(error));
};

const getClient = async (accessToken, guid, region) =>
	fetch(`${backofficeApiUrl}/lookup/${guid}?instance=${region}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		method: 'GET'
	});

const putClient = async (accessToken, { guid, region, status, start, end }) =>
	fetch(
		`${backofficeApiUrl}/subscription/${guid}?instance=${region}&status=${status}&start=${format(
			toDate(parseISO(start)),
			'yyyy-MM-dd'
		)}&end=${format(toDate(parseISO(end)), 'yyyy-MM-dd')}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			method: 'PUT'
		}
	);

const App = () => {
	const { instance, accounts, inProgress } = useMsal();
	const account = useAccount(accounts[0] || {});
	const [aadToken, setAadToken] = useState(null);
	const [bffData, setBffData] = useState(null);

	useEffect(() => {
		if (!bffData && aadToken) {
			fetchData(aadToken, backofficeApiUrl).then((response2) => setBffData(response2));
		}
	}, [bffData, aadToken]);

	useEffect(() => {
		if (account && inProgress === 'none' && !aadToken) {
			instance
				.acquireTokenSilent({
					scopes: backofficeApiScopes,
					account
				})
				.then((response) => {
					setAadToken(response.accessToken);
				})
				.catch((error) => {
					console.log(error);
					if (error instanceof InteractionRequiredAuthError) {
						if (account && inProgress === 'none') {
							instance
								.acquireTokenPopup({
									scopes: backofficeApiScopes
								})
								.then((response) => {
									setAadToken(response.accessToken);
								})
								.catch((error2) => console.error(error2));
						}
					}
				});
		}
	}, [account, inProgress, instance, aadToken, accounts]);

	const [selectedClient, setSelectedClient] = useState(
		JSON.parse(window.sessionStorage?.getItem('sessionSelectedClient')) || {}
	);
	const [clientLookup, setClientLookup] = useState(
		JSON.parse(window.sessionStorage?.getItem('sessionClientLookup')) || null
	);
	const [currentPath, setCurrentPath] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedDetail, setSelectedDetail] = useState(null);
	const [isFirstSearch, setIsFirstSearch] = useState(true);
	const [isLinkedFromDetails, setIsLinkedFromDetails] = useState(false);
	const [searchResults, setSearchResults] = useState(
		JSON.parse(window.sessionStorage?.getItem('sessionSearchResults')) || null
	);
	const [changeData, setChangeData] = useState(
		selectedDetail &&
			selectedClient?.subscription &&
			selectedClient?.subscription[selectedDetail]
	);
	const [resError, setResError] = useState(false);
	const [actionsComplete, setActionsComplete] = useState(true);

	useEffect(() => {
		if (searchResults?.items) {
			window.sessionStorage.setItem('sessionSearchResults', JSON.stringify(searchResults));
		}
	}, [searchResults]);
	useEffect(() => {
		if (clientLookup) {
			window.sessionStorage.setItem('sessionClientLookup', JSON.stringify(clientLookup));
		}
	}, [clientLookup]);
	useEffect(() => {
		if (selectedClient) {
			window.sessionStorage.setItem('sessionSelectedClient', JSON.stringify(selectedClient));
		}
	}, [selectedClient]);

	return (
		<BrowserRouter>
			<ColumnLayout
				aadToken={aadToken}
				currentPath={currentPath}
				setCurrentPath={setCurrentPath}
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
				selectedClient={selectedClient}
				selectedDetail={selectedDetail}
				setSelectedClient={setSelectedClient}
				setIsFirstSearch={setIsFirstSearch}
				setIsLinkedFromDetails={setIsLinkedFromDetails}
				changeData={changeData}
				setChangeData={setChangeData}
				putClient={putClient}
				getClient={getClient}
				clientLookup={clientLookup}
				setClientLookup={setClientLookup}
				setResError={setResError}
				resError={resError}
				actionsComplete={actionsComplete}
				setActionsComplete={setActionsComplete}
			>
				<AuthenticatedTemplate>
					<Switch>
						<Route exact path="/home">
							<ClientSearch
								setSelectedClient={setSelectedClient}
								selectedClient={selectedClient}
								setIsFirstSearch={setIsFirstSearch}
								isFirstSearch={isFirstSearch}
								searchResults={searchResults}
								setSearchResults={setSearchResults}
								isLinkedFromDetails={isLinkedFromDetails}
								setIsLinkedFromDetails={setIsLinkedFromDetails}
								instance={instance}
								account={account}
								accounts={accounts}
								aadToken={aadToken}
							/>
						</Route>
						<Route exact path="/client-details">
							<ClientDetails
								selectedClient={selectedClient}
								setIsLinkedFromDetails={setIsLinkedFromDetails}
								setModalOpen={setModalOpen}
								setSelectedDetail={setSelectedDetail}
								setChangeData={setChangeData}
								clientLookup={clientLookup}
								setClientLookup={setClientLookup}
								getClient={getClient}
								setResError={setResError}
								resError={resError}
								selectedDetail={selectedDetail}
								changeData={changeData}
								instance={instance}
								account={account}
								accounts={accounts}
								aadToken={aadToken}
							/>
						</Route>
						<Route exact path="/password-scramble">
							<PasswordScramble aadToken={aadToken} />
						</Route>
						<Redirect from="/login" to="/home" />
						<Redirect from="/" to="/home" />
						<Route component={NotFound} />
					</Switch>
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<Switch>
						<Route exact path="/login">
							<Login />
						</Route>
						<Redirect to="/login" />
					</Switch>
				</UnauthenticatedTemplate>
			</ColumnLayout>
		</BrowserRouter>
	);
};

export default App;
