import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import {
	Table,
	TableHeader,
	TableRow,
	TableHeaderCell,
	TableCell,
	TableBody
} from '@brighthr/component-table';
import Icon from '@brighthr/component-icon';
import Button from '@brighthr/component-button';
import Switch from '@brighthr/component-switch';
import Tooltip from '@brighthr/component-tooltip';
import getConfig from '../../utils/config';
import Section from '../../components/Layout/Section';
import Spinner from '../../components/Spinner';

const { backofficeApiUrl } = getConfig();

const getSearchResults = async (accessToken, searchString, region) =>
	fetch(`${backofficeApiUrl}/search?instance=${region}&q=${searchString}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		method: 'GET'
	});

const getUserSearchResults = async (accessToken, searchString, region) =>
	fetch(`${backofficeApiUrl}/search/employee?instance=${region}&q=${searchString}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		method: 'GET'
	});

const Accordion = ({ children, forceExpanded, client, setSelectedClient }) => {
	const [isOpen, setIsOpen] = useState(forceExpanded);
	useEffect(() => {
		setIsOpen(forceExpanded);
	}, [forceExpanded]);

	return (
		<div>
			<tr className="flex items-center w-full h-12 border-b cursor-pointer bg-neutral-100">
				<td className="font-semibold lg:max-w-[400px] lg:min-w-[400px] max-w-[180px] min-w-[180px]">
					<button
						className="pl-4 pr-1 lg:max-w-[390px] lg:min-w-[390px] max-w-[170px] min-w-[170px] text-left overflow-hidden truncate"
						type="button"
						onClick={() => {
							setSelectedClient(client);
						}}
					>
						<Link to="/client-details">{client.name}</Link>
					</button>
				</td>
				<td className="font-semibold lg:max-w-[400px] lg:min-w-[400px] max-w-[180px] min-w-[180px]">
					<button
						type="button"
						className="pl-4 pr-1 lg:max-w-[390px] lg:min-w-[390px] max-w-[170px] min-w-[170px] text-left overflow-hidden truncate"
						onClick={() => {
							setSelectedClient(client);
						}}
					>
						<Link to="/client-details">{client.externalReference}</Link>
					</button>
				</td>
				<td className="font-semibold text-left truncate lg:max-w-[320px] lg:w-[320px] max-w-[290px] w-[290px]">
					<button
						type="button"
						className="overflow-hidden truncate"
						onClick={() => {
							setSelectedClient(client);
						}}
					>
						<Link to="/client-details">{client.guid}</Link>
					</button>
				</td>
				<td>
					<button className="pl-12 pr-2" type="button" onClick={() => setIsOpen(!isOpen)}>
						<Icon
							iconName={`${isOpen ? 'chevron-thick-up' : 'chevron-thick-down'}`}
							size={22}
							className="inline-block align-top fill-accent-500"
						/>
					</button>
				</td>
			</tr>
			{isOpen && <div>{children}</div>}
		</div>
	);
};

export default ({
	setSelectedClient,
	setIsFirstSearch,
	isFirstSearch,
	searchResults,
	setSearchResults,
	isLinkedFromDetails,
	setIsLinkedFromDetails,
	aadToken
}) => {
	const [allExpanded, setAllExpanded] = useState(true);
	const [isUserSearchActive, setIsUserSearchActive] = useState(
		window.sessionStorage.getItem('isUserSearchActive') || false
	);
	useEffect(() => {
		window.sessionStorage.setItem('isUserSearchActive', isUserSearchActive);
	}, [isUserSearchActive]);

	const [actionsComplete, setActionsComplete] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const [resError, setResError] = useState(false);

	const clear = () => {
		setSearchResults(null);
		setSearchValue('');
	};

	useEffect(() => {
		setIsFirstSearch(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	useEffect(() => {
		setSearchResults(searchResults);
	}, [searchResults, setSearchResults]);

	const [searchRegion, setSearchRegion] = useState('UK');
	return (
		<Section>
			<h1 className="pb-2 text-2xl">Client search</h1>
			<div>
				<button
					type="button"
					className={classNames('pb-2 text-center w-48', {
						'text-primary-700 border-b-[3px] border-primary-700 font-semibold':
							!JSON.parse(window.sessionStorage.getItem('isUserSearchActive'))
					})}
					onClick={() => {
						clear();
						setIsUserSearchActive(false);
						window.sessionStorage.setItem('isUserSearchActive', 'false');
					}}
				>
					Search by company
				</button>

				<button
					type="button"
					className={classNames('pb-2 text-center w-40', {
						'text-primary-700 border-b-[3px] border-primary-700 font-semibold':
							JSON.parse(window.sessionStorage.getItem('isUserSearchActive'))
					})}
					onClick={() => {
						clear();
						setIsUserSearchActive(true);
						window.sessionStorage.setItem('isUserSearchActive', 'true');
					}}
				>
					Search by user
				</button>
			</div>
			<form
				className={classNames({
					'h-32 lg:h-auto':
						(!searchResults?.items.length && actionsComplete) ||
						searchResults?.fetchMore ||
						resError ||
						searchResults?.items.length ||
						!actionsComplete
				})}
				onSubmit={async (e) => {
					if (isUserSearchActive) {
						setIsLinkedFromDetails(false);
						setSearchResults(null);
						setIsFirstSearch(false);
						e.preventDefault();
						setActionsComplete(false);
						if (searchValue.length < 3) {
							return null;
						}
						try {
							await getUserSearchResults(aadToken, searchValue, searchRegion).then(
								(res) => {
									if (res.ok) {
										res.json().then((data) => {
											setSearchResults({
												...data,
												items: data.items.sort((a, b) =>
													a.name > b.name ? 1 : -1
												)
											});
										});
									} else {
										setResError(true);
									}
								}
							);
						} catch (_e) {
							console.log(_e);
							setResError(true);
						}
						setActionsComplete(true);
					} else {
						setIsLinkedFromDetails(false);
						setSearchResults(null);
						setIsFirstSearch(false);
						e.preventDefault();
						setActionsComplete(false);
						if (searchValue.length < 3) {
							return null;
						}
						try {
							await getSearchResults(aadToken, searchValue, searchRegion).then(
								(res) => {
									if (res.ok) {
										res.json().then((data) => {
											setSearchResults({
												...data,
												items: data.items.sort((a, b) =>
													a.name > b.name ? 1 : -1
												)
											});
										});
									} else {
										setResError(true);
									}
								}
							);
						} catch (_e) {
							console.log(_e);
							setResError(true);
						}
						setActionsComplete(true);
					}
				}}
			>
				<label htmlFor="default-search" className="mb-2 text-sm font-medium sr-only">
					Search
				</label>
				<div className="flex flex-col mb-1 lg:flex-row max-h-16">
					<div>
						<input
							type="text"
							value={searchValue}
							onChange={(e) => {
								setSearchValue(e.target.value);
							}}
							id="client-search"
							className="rounded font p-2 text-gray-900 border-2 border-neutral-300 text-ellipsis mt-3 pl-11 bg-white w-[685px] lg:w-[665px]"
							placeholder={
								isUserSearchActive
									? 'Enter name, email or user id...'
									: 'Enter company name or account number...'
							}
							required=""
						/>
						<div className="relative flex items-center pl-3 pointer-events-none left-1 bottom-8">
							<svg
								aria-hidden="true"
								className="w-5 h-5 text-neutral-300"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
								/>
							</svg>
						</div>
					</div>
					<button
						type="button"
						className="relative hidden rounded-full lg:block hover:cursor-pointer right-[30px]"
					>
						<Icon
							iconName="cross-thin"
							className="block p-1 resize-none fill-neutral-700"
							size={24}
							onClick={() => {
								clear();
							}}
						/>
					</button>
					<div className="flex items-center flex-auto mt-1">
						<div className="flex items-center h-full pr-3">
							<div className="pr-2">Region</div>
							<select
								onChange={(e) => {
									setSearchRegion(e.target.value);
								}}
								className="pl-1 ml-3 mr-3 font-semibold text-gray-900 border-2 rounded border-neutral-300 h-9 w-28"
								label="Select country"
							>
								<option value="UK" label="UK" />
								<option value="AU" label="ANZ" />
								<option value="IE" label="IE" />
								<option value="CA" label="CA" />
							</select>
						</div>
						<div className="flex flex-shrink-0">
							<Button
								type="submit"
								text="Find company"
								color="accent"
								disabled={!actionsComplete || searchValue.length < 3}
							/>
						</div>
						{isUserSearchActive && (
							<div className="flex flex-row items-center ml-[200px] lg:ml-9">
								<div className="flex-none mr-3">Expanded</div>
								<Switch
									defaultChecked={allExpanded}
									onClick={() => {
										setAllExpanded(!allExpanded);
									}}
								/>
							</div>
						)}
					</div>
				</div>
			</form>

			{searchResults?.fetchMore &&
				actionsComplete &&
				!resError &&
				(!isFirstSearch || isLinkedFromDetails) && (
					<div className="flex flex-row justify-center pb-3 my-2 text-lg font-semibold">
						Too many results, please update your search criteria.
					</div>
				)}
			{searchResults?.items.length &&
			(!isFirstSearch || isLinkedFromDetails) &&
			!isUserSearchActive ? (
				<Table>
					<TableHeader key="resultsHeader">
						<TableRow>
							<TableHeaderCell>Company name</TableHeaderCell>
							<TableHeaderCell>Client account number</TableHeaderCell>
							<TableHeaderCell>Customer ID</TableHeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{searchResults.items.map((client) => (
							<>
								<TableRow key={`resultRow-${client.guid}`}>
									<TableCell>
										<button
											type="button"
											onClick={() => {
												setSelectedClient(client);
											}}
										>
											<Link to="/client-details">
												<div className="text-left max-w-[30rem] truncate">
													{client.name}
												</div>
											</Link>
										</button>
									</TableCell>
									<TableCell>
										<button
											type="button"
											onClick={() => {
												setSelectedClient(client);
											}}
										>
											<Link to="/client-details">
												<div className="text-left truncate min-w-[10rem]">
													{client.externalReference}
												</div>
											</Link>
										</button>
									</TableCell>
									<TableCell>
										<button
											type="button"
											onClick={() => {
												setSelectedClient(client);
											}}
										>
											<Link to="/client-details">
												<div className="text-left truncate">
													{client.guid}
												</div>
											</Link>
										</button>
									</TableCell>
								</TableRow>
							</>
						))}
					</TableBody>
				</Table>
			) : null}
			{searchResults?.items.length &&
			(!isFirstSearch || isLinkedFromDetails) &&
			isUserSearchActive ? (
				<table>
					<tr className="flex flex-row py-3 font-bold text-left">
						<th className="pl-4 lg:max-w-[400px] lg:min-w-[400px] max-w-[180px] min-w-[180px]">
							Company name
						</th>
						<th className="lg:max-w-[400px] lg:min-w-[400px] max-w-[180px] min-w-[180px]">
							Client account number
						</th>
						<th className="lg:max-w-[400px] lg:min-w-[400px] min-w-[220px]">
							Customer ID
						</th>
					</tr>
					{searchResults.items.map((client) => (
						<Accordion
							key={client.guid}
							client={client}
							forceExpanded={allExpanded}
							setSelectedClient={setSelectedClient}
						>
							<table>
								<tbody>
									{client?.employees?.map((employee) => (
										<tr
											key={employee.guid}
											className={classNames(
												'h-9 w-full border-b last:border-0',
												{
													'text-neutral-700': !employee.terminated
												},
												{
													'text-neutral-300': employee.terminated
												}
											)}
										>
											<td className="lg:max-w-[400px] lg:min-w-[400px] max-w-[180px] min-w-[180px] pl-8 truncate">
												{employee.name}
											</td>
											<td className="lg:max-w-[400px] lg:min-w-[400px] max-w-[180px] min-w-[180px] pl-8 truncate">
												{employee.emailAddress}
											</td>
											<td className="max-w-[402px] lg:min-w-[364px] pl-4 truncate">
												{employee.guid}
											</td>
											{employee.terminated && (
												<Tooltip
													className="mt-1 ml-1 fill-neutral-300"
													iconName="employee-terminated"
												>
													Terminated
												</Tooltip>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</Accordion>
					))}
				</table>
			) : null}
			{!actionsComplete && !resError && <Spinner />}
			{!searchResults?.items.length &&
				actionsComplete &&
				(!isFirstSearch || isLinkedFromDetails) && (
					<div className="my-2 text-lg font-semibold ml-80">
						{resError
							? `Failed to get information for '${searchValue}'`
							: 'No results, please update your search criteria.'}
					</div>
				)}
		</Section>
	);
};
