import { React } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@brighthr/component-button';
import Section from '../../components/Layout/Section';

const NotFound = () => {
	const history = useHistory();

	return (
		<Section>
			<div className="flex flex-col items-center space-y-6">
				<h1 className="pl-1 text-4xl font-bold sm:text-5xl">Page not found</h1>
				<Button onClick={() => history.push('/')} text="Go back home" color="primary" />
			</div>
		</Section>
	);
};

export default NotFound;
