import React, { useEffect } from 'react';
import { format, parseISO, toDate } from 'date-fns';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Icon from '@brighthr/component-icon';
import classNames from 'classnames';
import Section from '../../components/Layout/Section';
import Spinner from '../../components/Spinner';

export default ({
	selectedClient,
	setIsLinkedFromDetails,
	setModalOpen,
	setSelectedDetail,
	setChangeData,
	clientLookup,
	setClientLookup,
	getClient,
	resError,
	setResError,
	instance,
	account,
	aadToken,
	response
}) => {
	const ClientItem = ({ title, value, dataProp, edit }) => (
		<div
			className={classNames(
				'flex flex-col px-2',
				title === 'Customer ID' ? 'w-[32rem]' : 'w-64'
			)}
		>
			<p className="pb-1 font-semibold">{title}</p>
			{edit ? (
				<button
					onClick={() => {
						setSelectedDetail(dataProp);
						setChangeData(clientLookup?.subscription[dataProp]);
						setModalOpen(true);
					}}
					type="button"
					className="text-left text-primary-600 hover:text-primary-500"
				>
					{value}
				</button>
			) : (
				<div className="text-left">{value}</div>
			)}
		</div>
	);

	useEffect(() => {
		async function getDetails() {
			try {
				await getClient(aadToken, selectedClient.guid, selectedClient.instance).then(
					(res) => {
						if (res.ok) {
							res.json().then((data) => {
								setClientLookup(data);
								setResError(false);
							});
						} else {
							setResError(true);
						}
					}
				);
			} catch (e) {
				console.log(e);
				setResError(true);
			}
		}
		getDetails();
	}, [
		account,
		instance,
		aadToken,
		selectedClient,
		setClientLookup,
		getClient,
		setResError,
		response
	]);

	if (clientLookup && !resError) {
		return (
			<Section>
				<h1 className="pb-6 text-2xl">{clientLookup?.name}</h1>
				<Link to="/client-search">
					<button
						className="text-left pb-7 text-primary-600 hover:text-primary-500 fill-primary-600 hover:fill-primary-500"
						type="button"
						onClick={() => {
							setIsLinkedFromDetails(true);
						}}
					>
						<div className="flex font-semibold">
							<Icon
								iconName="chevron-thick-left"
								className="mt-[2px] mr-2"
								size={20}
							/>
							<div>Back to search results</div>
						</div>
					</button>
				</Link>
				<div className="bg-gray-50">
					<div className="flex w-full py-3 pl-4 pr-16 mb-8">
						<ClientItem
							title="Client account number"
							value={clientLookup?.externalReference}
						/>
						<ClientItem title="Customer ID" value={clientLookup?.guid} />
						<ClientItem title="Region" value={selectedClient?.instance} />
					</div>
					<h2 className="pt-2 pb-2 pl-6 font-semibold bg-gray-100">Contract details</h2>
					<div className="flex w-full py-3 pl-4">
						<ClientItem
							title="Start date"
							value={`${format(
								toDate(parseISO(clientLookup?.subscription?.start)),
								'EE dd MMM yyyy'
							)}`}
							dataProp="start"
							edit
						/>
						<ClientItem
							title="End date"
							value={`${format(
								toDate(parseISO(clientLookup?.subscription?.end)),
								'EE dd MMM yyyy'
							)}`}
							dataProp="end"
							edit
						/>
						<ClientItem
							title="Status"
							edit
							value={clientLookup?.subscription?.status}
							dataProp="status"
						/>
						<ClientItem
							title="Services"
							value={clientLookup?.subscription?.services?.join(', ')}
						/>
						<div className="w-60" />
					</div>
				</div>
			</Section>
		);
	}

	return (
		<Section>
			<Link to="/client-search">
				<p className="text-primary-600 hover:text-primary-500">Back to search results</p>
			</Link>
			{resError ? <div>Error</div> : <Spinner />}
		</Section>
	);
};
