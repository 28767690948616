import React, { useEffect, useState } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import Button from '@brighthr/component-button';
import DatePicker from '@brighthr/component-datepicker';
import { Modal, ModalBody, ModalFooter } from '@brighthr/component-modal';
import Select from '@brighthr/component-select';
import classNames from 'classnames';
import { Toaster, useToast } from '@brighthr/component-toast';
import { addYears, parseISO } from 'date-fns';
import Spinner from '../../Spinner';
import getConfig from '../../../utils/config';
import Sidebar from '../Sidebar';
import '@brighthr/component-datepicker/dist/index.css';
import './index.css';

const { msGraphApiScopes, msGraphApiUrl } = getConfig();

const fetchData = (accessToken, apiEndpoint) => {
	// eslint-disable-next-line no-undef
	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;
	headers.append('Authorization', bearer);
	const options = {
		method: 'GET',
		headers
	};
	return fetch(apiEndpoint, options)
		.then((response) => response.json())
		.catch((error) => console.error(error));
};

const modalHeadings = {
	start: 'start date',
	end: 'end date',
	status: 'status',
	services: 'services'
};

const toastHeadings = {
	start: 'Start date',
	end: 'End date',
	status: 'Status',
	services: 'Services'
};

export default ({
	aadToken,
	children,
	currentPath,
	setCurrentPath,
	selectedClient,
	modalOpen,
	setModalOpen,
	selectedDetail,
	setIsLinkedFromDetails,
	changeData,
	setChangeData,
	putClient,
	clientLookup,
	setClientLookup,
	getClient,
	setResError,
	actionsComplete,
	setActionsComplete
}) => {
	setCurrentPath(window.location.pathname);
	const [menuOpen, setMenuOpen] = useState(true);
	const [userData, setUserData] = useState(null);
	const { instance, accounts, inProgress } = useMsal();
	const account = useAccount(accounts[0] || {});
	const { createToast } = useToast();

	useEffect(() => {
		if (account && inProgress === 'none' && !userData) {
			instance
				.acquireTokenSilent({
					scopes: msGraphApiScopes,
					account
				})
				.then((response) => {
					fetchData(response.accessToken, msGraphApiUrl).then((response2) =>
						setUserData(response2)
					);
				})
				.catch((error) => {
					if (error instanceof InteractionRequiredAuthError) {
						if (account && inProgress === 'none') {
							instance
								.acquireTokenRedirect({
									scopes: msGraphApiScopes
								})
								.then((response) => {
									fetchData(response.accessToken, msGraphApiUrl).then(
										(response2) => setUserData(response2)
									);
								})
								.catch((error2) => console.error(error2));
						}
					}
				});
		}
	}, [account, inProgress, instance, userData]);

	const [isDateValid, setIsDateValid] = useState(false);

	useEffect(() => {
		switch (selectedDetail) {
			default:
				setIsDateValid(true);
				break;
			case 'end':
				setIsDateValid(changeData > clientLookup?.subscription?.start);
				break;
			case 'start':
				setIsDateValid(changeData < clientLookup?.subscription?.end);
				break;
		}
	}, [changeData, clientLookup, selectedDetail]);

	return (
		<div className="flex h-full min-h-screen overflow-hidden bg-white">
			<Sidebar
				aadToken={aadToken}
				userData={userData}
				setUserData={setUserData}
				menuOpen={menuOpen}
				setMenuOpen={setMenuOpen}
				currentPath={currentPath}
				setCurrentPath={setCurrentPath}
				selectedClient={selectedClient}
				setIsLinkedFromDetails={setIsLinkedFromDetails}
			/>
			<div className={classNames('flex overflow-hidden', { 'md:ml-[14rem]': menuOpen })}>
				<main className="flex p-3 pt-6 overflow-hidden">{children}</main>
				{modalOpen && (
					<Modal
						allowOverflow
						width="sm"
						close={() => {
							setModalOpen(false);
						}}
						title={`Edit ${modalHeadings[selectedDetail]}`}
					>
						<ModalBody>
							{actionsComplete ? (
								<>
									{selectedDetail !== 'status' ? (
										<DatePicker
											disabled={!actionsComplete}
											date={new Date(changeData)}
											maxDate={
												selectedDetail === 'start'
													? parseISO(clientLookup?.subscription?.end)
													: addYears(
															parseISO(
																clientLookup?.subscription?.start
															),
															10
													  )
											}
											minDate={
												selectedDetail === 'start'
													? new Date(2016, 1, 1)
													: parseISO(clientLookup?.subscription?.start)
											}
											setDate={(d) => {
												setChangeData(d.toISOString());
											}}
										/>
									) : (
										<Select
											onChange={(e) => {
												setChangeData(e.target.value);
											}}
											defaultValue={clientLookup?.subscription?.status}
										>
											<option value="Active" label="Active" />
											<option value="Invalid" label="Invalid" />
											<option value="Stopped" label="Stopped" />
										</Select>
									)}
								</>
							) : (
								<Spinner />
							)}
						</ModalBody>
						<ModalFooter>
							<Button
								onClick={() => {
									setModalOpen(false);
								}}
								text="Cancel"
								color="accent"
								disabled={!actionsComplete}
								outlineButton
							/>
							<Button
								disabled={!actionsComplete || !isDateValid}
								onClick={async () => {
									setActionsComplete(false);
									await putClient(aadToken, {
										guid: clientLookup?.guid,
										region: clientLookup?.instance,
										status:
											selectedDetail === 'status'
												? changeData
												: clientLookup?.subscription?.status,

										start:
											selectedDetail === 'start'
												? changeData
												: clientLookup?.subscription?.start,

										end:
											selectedDetail === 'end'
												? changeData
												: clientLookup?.subscription?.end
									}).then((res) => {
										if (res.ok) {
											res.json().then((data) => {
												setClientLookup(data);
											});
										} else {
											setResError(true);
										}
									});
									try {
										await getClient(
											aadToken,
											selectedClient?.guid,
											selectedClient?.instance
										).then((res) => {
											if (res.ok) {
												res.json().then((data) => {
													setClientLookup(data);
												});
												setActionsComplete(true);
												setModalOpen(false);
												createToast({
													text: `${toastHeadings[selectedDetail]} updated`,
													type: 'success'
												});
											} else {
												setActionsComplete(true);
												setModalOpen(false);
												createToast({
													text: 'Error',
													type: 'error'
												});
												setResError(true);
											}
										});
									} catch (e) {
										console.log(e);
										setActionsComplete(true);
										setModalOpen(false);
										createToast({
											text: 'Error',
											type: 'error'
										});
										setResError(true);
									}
								}}
								text="Confirm"
								color="accent"
							/>
						</ModalFooter>
					</Modal>
				)}
			</div>
			<Toaster />
		</div>
	);
};
